









































































import Vue from 'vue'
import './auth.scss'
import { Component } from 'vue-property-decorator'
import { IUser, IUserState } from '@store/user/Interface'
import { userNamespace } from '@store/user'
import { commonNamespace } from '@store/common'
import { UserActionTypes } from '@store/user/Types'
import { CommonActionTypes } from '@store/common/Types'

interface IAuth {
  email: string
  password: string
}

@Component
export default class Auth extends Vue {
  @userNamespace.Action(UserActionTypes.A_LOGIN) private fetchUser!: (auth: IAuth) => Promise<IUser>

  @commonNamespace.Action(CommonActionTypes.A_AUTH_USER) private authUser!: (auth: boolean) => Promise<void>

  @userNamespace.State('user') private user!: IUser

  private auth: IAuth = {
    email: '',
    password: '',
  }

  private error: string = ''
  private errorRequest: boolean = false

  private login() {
    this.fetchUser(this.auth)
      .then(response => {
        this.authUser(true)
        if (this.user.role === 'admin') {
          this.$router.push({ path: '/admin' })
        } else {
          this.$router.push({ name: 'home' })
        }
      })
      .catch(error => {
        this.error = error.response.data.message
        this.errorRequest = true
        this.authUser(false)
      })
  }
}
